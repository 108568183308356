/**
 * @fileoverview gRPC-Web generated client stub for service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: service/transaction.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as command_transaction_pb from '../command/transaction_pb'; // proto import: "command/transaction.proto"
import * as query_transaction_pb from '../query/transaction_pb'; // proto import: "query/transaction.proto"


export class TransactionServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorFetchTransactionPurposes = new grpcWeb.MethodDescriptor(
    '/service.TransactionService/FetchTransactionPurposes',
    grpcWeb.MethodType.UNARY,
    query_transaction_pb.FetchTransactionPurposesQueryRequest,
    query_transaction_pb.FetchTransactionPurposesQueryResponse,
    (request: query_transaction_pb.FetchTransactionPurposesQueryRequest) => {
      return request.serializeBinary();
    },
    query_transaction_pb.FetchTransactionPurposesQueryResponse.deserializeBinary
  );

  fetchTransactionPurposes(
    request: query_transaction_pb.FetchTransactionPurposesQueryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<query_transaction_pb.FetchTransactionPurposesQueryResponse>;

  fetchTransactionPurposes(
    request: query_transaction_pb.FetchTransactionPurposesQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: query_transaction_pb.FetchTransactionPurposesQueryResponse) => void): grpcWeb.ClientReadableStream<query_transaction_pb.FetchTransactionPurposesQueryResponse>;

  fetchTransactionPurposes(
    request: query_transaction_pb.FetchTransactionPurposesQueryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: query_transaction_pb.FetchTransactionPurposesQueryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.TransactionService/FetchTransactionPurposes',
        request,
        metadata || {},
        this.methodDescriptorFetchTransactionPurposes,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.TransactionService/FetchTransactionPurposes',
    request,
    metadata || {},
    this.methodDescriptorFetchTransactionPurposes);
  }

  methodDescriptorCreateTransactionPurpose = new grpcWeb.MethodDescriptor(
    '/service.TransactionService/CreateTransactionPurpose',
    grpcWeb.MethodType.UNARY,
    command_transaction_pb.CreateTransactionPurposeCommandRequest,
    command_transaction_pb.CreateTransactionPurposeCommandResponse,
    (request: command_transaction_pb.CreateTransactionPurposeCommandRequest) => {
      return request.serializeBinary();
    },
    command_transaction_pb.CreateTransactionPurposeCommandResponse.deserializeBinary
  );

  createTransactionPurpose(
    request: command_transaction_pb.CreateTransactionPurposeCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<command_transaction_pb.CreateTransactionPurposeCommandResponse>;

  createTransactionPurpose(
    request: command_transaction_pb.CreateTransactionPurposeCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: command_transaction_pb.CreateTransactionPurposeCommandResponse) => void): grpcWeb.ClientReadableStream<command_transaction_pb.CreateTransactionPurposeCommandResponse>;

  createTransactionPurpose(
    request: command_transaction_pb.CreateTransactionPurposeCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: command_transaction_pb.CreateTransactionPurposeCommandResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.TransactionService/CreateTransactionPurpose',
        request,
        metadata || {},
        this.methodDescriptorCreateTransactionPurpose,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.TransactionService/CreateTransactionPurpose',
    request,
    metadata || {},
    this.methodDescriptorCreateTransactionPurpose);
  }

}


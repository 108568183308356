class IDToken {
    private readonly _displayName: string;
    private readonly _authExpiresAt: Date;

    constructor(displayName: string, authExpiresAt: Date) {
        this._displayName = displayName;
        this._authExpiresAt = authExpiresAt;
    }

    public get displayName(): string {
        return this._displayName;
    }

    public get authExpiresAt(): Date {
        return this._authExpiresAt;
    }
}

export default IDToken;

const logout = {
    en: {
        translation: {
            "logout.signing_out": "Signing out...",
            "logout.signed_out": "Signed out"
        }
    },
    de: {
        translation: {
            "logout.signing_out": "Abmelden...",
            "logout.signed_out": "Abgemeldet"
        }
    },
    ru: {
        translation: {
            "logout.signing_out": "Выход...",
            "logout.signed_out": "Вы вышли"
        }
    }
};

export { logout };
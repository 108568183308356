import {FunctionalComponent, h} from "preact";
import style from "./style.css";

interface TitleBackBtnProps {
    title: string;
    canGoBack: boolean;
}

/**
 * Title back button component properties.
 * @param title
 * @param canGoBack
 * @constructor
 */
const TitleBackBtn: FunctionalComponent<TitleBackBtnProps> = ({title, canGoBack}) => {
    const handleClick = () => {
        window.history.back();
    };

    const render = () => {
        if (!canGoBack) {
            return (
                <div class={`${style.headerWoButton}`}>
                    <div class={`row`}/>
                    <div class={`col-12 ${style.centerText}`}>
                        <h1 class={`${style.title}`}>{title}</h1>
                    </div>
                </div>
            );
        }

        return (
            <div class={`${style.header}`}>
                <div class={`row`}>
                    <div class={`col-2`}>
                        <button type={"button"} class={`${style.backButton}`} onClick={handleClick}>
                            <i class="fas fa-chevron-left"></i>
                        </button>
                    </div>
                </div>
                <div class={`col-10 ${style.centerText}`}>
                    <h1 class={`${style.title}`}>{title}</h1>
                </div>
            </div>
        );
    }

    return (render());
}

export default TitleBackBtn;
import {FunctionalComponent, h} from "preact";
import {Route, Router} from "preact-router";

import Header from "./header";

// Code-splitting is automated for `routes` directory
import Home from "../routes/home";
import Login from "../routes/login";
import AuthClientService from "../auth/AuthClientService";
import Accounts from "../routes/protected/accounts";
import ProtectedRoute from "../routes/protected/ProtectedRoute";
import SignOut from "../routes/protected/logout";
import UserProvider from "../auth/UserProvider";
import {AccountServiceClient} from "../generated/service/AccountServiceClientPb";
import TxPurposes from "../routes/protected/tx-purposes";
import {TransactionServiceClient} from "../generated/service/TransactionServiceClientPb";
import AddOneTimeExpense from "../routes/protected/accounts/add/one-time-expense";
import AddOneTimeIncome from "../routes/protected/accounts/add/one-time-income";
import FetchDetails from "../routes/protected/accounts/fetch/details";
import NotFound from "../routes/not-found";
import {I18nextProvider} from "react-i18next";
import i18n from "../i18n";
import AddOneTimeTransfer from "../routes/protected/accounts/add/one-time-transfer";

const App: FunctionalComponent = () => {
    const authClient = new AuthClientService();
    const txServiceClient = new TransactionServiceClient("/api", {}, {credentials: "same-origin"});
    const accountServiceClient = new AccountServiceClient("/api", {}, {credentials: "same-origin"});

    return (
        <I18nextProvider i18n={i18n}>
            <UserProvider authClient={authClient}>
                <div id="app">
                    <Header/>
                    <main>
                        <Router>
                            <Route path="/" component={Home}/>
                            <Route
                                path="/sign-in/"
                                component={() => <Login authClient={authClient}/>}
                            />
                            <ProtectedRoute
                                path="/accounts/"
                                fallbackPath="/sign-in"
                                accountServiceClient={accountServiceClient}
                                component={Accounts}
                            />
                            <ProtectedRoute
                                path="/accounts/:accountID/add/one-time-expense/"
                                fallbackPath="/sign-in"
                                txServiceClient={txServiceClient}
                                accountServiceClient={accountServiceClient}
                                component={AddOneTimeExpense}
                            />
                            <ProtectedRoute
                                path="/accounts/:accountID/add/one-time-income/"
                                fallbackPath="/sign-in"
                                txServiceClient={txServiceClient}
                                accountServiceClient={accountServiceClient}
                                component={AddOneTimeIncome}
                            />
                            <ProtectedRoute
                                path="/accounts/:srcAccountID/add/one-time-transfer/"
                                fallbackPath="/sign-in"
                                txServiceClient={txServiceClient}
                                accountServiceClient={accountServiceClient}
                                component={AddOneTimeTransfer}
                            />
                            <ProtectedRoute
                                path="/accounts/:accountID/fetch/details/"
                                fallbackPath="/sign-in"
                                accountServiceClient={accountServiceClient}
                                component={FetchDetails}
                            />
                            <ProtectedRoute
                                path="/tx/purposes/"
                                fallbackPath="/sign-in"
                                txServiceClient={txServiceClient}
                                component={TxPurposes}
                            />
                            <ProtectedRoute
                                path="/sign-out/"
                                fallbackPath="/"
                                authClient={authClient}
                                component={SignOut}
                            />
                            <Route default component={NotFound}/>
                        </Router>
                    </main>
                </div>
            </UserProvider>
        </I18nextProvider>
    );
};

export default App;

import {FunctionalComponent, h, JSX} from "preact";
import {AccountServiceClient} from "../../../../../generated/service/AccountServiceClientPb";
import genericStyle from "../../../style.css";
import {useState} from "preact/hooks";
import {useEffect} from "preact/compat";
import {
    FetchAccountDetailsQueryRequest,
    FetchAccountDetailsQueryResponse,
    FetchAccountListQueryRequest,
    FetchedAccountDetailsLevel
} from "../../../../../generated/query/account_pb";
import {TransactionServiceClient} from "../../../../../generated/service/TransactionServiceClientPb";
import {
    FetchTransactionPurposesQueryRequest,
    FetchTransactionPurposesQueryResponse
} from "../../../../../generated/query/transaction_pb";
import {AddOneTimeTransferCommandRequest, Transaction} from "../../../../../generated/command/account_pb";
import {formatCurrencyFromCents} from "../../../../../utils/formatting";
import {route} from "preact-router";
import {useTranslation} from "react-i18next";
import TitleBackBtn from "../../../../../components/button/TitleBackBtn";

/**
 * Add One Time Transfer component properties.
 */
interface AddOneTimeTransferProps {
    txServiceClient: TransactionServiceClient;
    accountServiceClient: AccountServiceClient;
    srcAccountID: string;
    dstAccountID: string;
}

/**
 * Transfer purpose interface.
 */
interface TransferPurpose {
    id: string;
    title: string;
}

/**
 * Selectable account interface.
 */
interface SelectableDstAccount {
    id: string;
    title: string;
}

/**
 * Add One Time Transfer component.
 */
const AddOneTimeTransfer: FunctionalComponent<AddOneTimeTransferProps> = (
    {
        txServiceClient,
        accountServiceClient,
        srcAccountID,
    }) => {
    const {t} = useTranslation();

    const [accountTitle, setAccountTitle] = useState<string>("");
    const [accountCurrency, setAccountCurrency] = useState<string>("");
    const [transferPurposes, setTransferPurposes] = useState<Array<TransferPurpose>>([]);
    const [amount, setAmount] = useState<number>(0);
    const [selectedPurposeID, setSelectedPurposeID] = useState<string>("-1");
    const [addTransferError, setAddTransferError] = useState<string>("");
    const [addTransferSuccess, setAddTransferSuccess] = useState<boolean>(false);
    const [selectableDstAccounts, setSelectableDstAccounts] = useState<Array<SelectableDstAccount>>([]);
    const [selectedDstAccountID, setSelectedDstAccountID] = useState<string>("-1");

    /**
     * Fetches account details from the server on component mount.
     */
    useEffect(() => {
        fetchSrcAccountDetails()
            .catch((err) => {
                setAddTransferSuccess(false);
                setAddTransferError(err.message);

                throw err;
            })
            .then((response: FetchAccountDetailsQueryResponse) => {
                setAccountTitle(response.getTitle());
                setAccountCurrency(response.getCurrency());
            });

        fetchSelectableDstAccountsList()
            .catch((err) => {
                setAddTransferSuccess(false);
                setAddTransferError(err.message);

                throw err;
            })
            .then((response: Array<SelectableDstAccount>) => {
                setSelectableDstAccounts(response);
            });

        fetchTransferPurposes()
            .catch((err) => {
                setAddTransferSuccess(false);
                setAddTransferError(err.message);

                throw err;
            })
            .then((response: FetchTransactionPurposesQueryResponse) => {
                const transferPurposes: Array<TransferPurpose> = response.getPurposesList().map((purpose) => {
                    return {id: purpose.getId(), title: purpose.getTitle()};
                });

                transferPurposes.sort((a: TransferPurpose, b: TransferPurpose) => {
                    return a.title.localeCompare(b.title);
                });

                setTransferPurposes(transferPurposes);
            });
    }, []);

    /**
     * Fetches transfer purposes from the server.
     */
    const fetchTransferPurposes = async () => {
        const fetchTransferPurposesRequest = new FetchTransactionPurposesQueryRequest();
        return txServiceClient.fetchTransactionPurposes(fetchTransferPurposesRequest);
    }

    /**
     * Fetches account details from the server.
     */
    const fetchSrcAccountDetails = async () => {
        const fetchDetailsRequest = new FetchAccountDetailsQueryRequest();
        fetchDetailsRequest.setAccountid(srcAccountID);
        fetchDetailsRequest.setDetailslevel(FetchedAccountDetailsLevel.FETCHED_ACCOUNT_DETAILS_LEVEL_MINIMAL);

        return accountServiceClient.fetchAccountDetails(fetchDetailsRequest);
    }

    /**
     * Fetches account list from the server.
     */
    const fetchSelectableDstAccountsList = async () => {
        const fetchedAccountList = await accountServiceClient.fetchAccountList(new FetchAccountListQueryRequest());

        // convert the fetched account list to a list of selectable accounts
        const selectableAccounts: Array<SelectableDstAccount> = fetchedAccountList.getAccountsList().map((account) => {
            return {id: account.getId(), title: account.getTitle()};
        }).filter((account) => account.id !== srcAccountID); // filter out the source account

        // sort the accounts by title
        selectableAccounts.sort((a: SelectableDstAccount, b: SelectableDstAccount) => {
            return a.title.localeCompare(b.title);
        });

        return selectableAccounts;
    }

    /**
     * Handles the form submission.
     * @param e
     */
    const handleAddOneTimeTransfer = (e: JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        const tx = new Transaction();
        tx.setAccountid(srcAccountID);
        tx.setAmount(amount);
        tx.setPurposeid(selectedPurposeID);

        const cmd = new AddOneTimeTransferCommandRequest();
        cmd.setTransaction(tx);
        cmd.setDestaccountid(selectedDstAccountID);

        accountServiceClient.addOneTimeTransfer(cmd)
            .catch((err) => {
                setAddTransferSuccess(false);
                setAddTransferError(err.message);

                throw err;
            })
            .then(() => {
                setAddTransferSuccess(true);
            });
    }

    /**
     * Renders the component.
     */
    return (
        <div class={"container-lg"}>
            <TitleBackBtn title={t("account.add.one-time-transfer.headline")} canGoBack={true}/>
            {addTransferError && <div class={"alert alert-danger"}>{addTransferError}</div>}
            {addTransferSuccess && <div
                class={"alert alert-success"}>{t("account.add.one-time-transfer.text.success", {amount: formatCurrencyFromCents(amount, accountCurrency)})}</div>}
            <form onSubmit={handleAddOneTimeTransfer}>
                <div class={"mb-3"}>
                    <label for="account-title-input"
                           class={"form-label"}>{t("account.add.one-time-transfer.text.label.src_account")}</label>
                    <input type="text" class={"form-control"} id="account-title-input" value={accountTitle} disabled/>
                </div>
                <div class={"mb-3"}>
                    <label for="choose-one-time-transfer-dst-account-select"
                           class={"form-label"}>{t("account.add.one-time-transfer.text.label.dst_account")}</label>
                    <select
                        class={"form-select"}
                        id="choose-one-time-transfer-dst-account-select"
                        onInput={(e) => setSelectedDstAccountID((e.target as HTMLSelectElement).value)}
                    >
                        <option value="-1"
                                selected>{t("account.add.one-time-transfer.text.select.choose_dst_account")}</option>
                        {selectableDstAccounts && selectableDstAccounts.map((account) => {
                            return <option value={account.id}>{account.title}</option>
                        })}
                    </select>
                </div>
                <div class={"mb-3"}>
                    <label for="choose-one-time-transfer-purpose-select"
                           class={"form-label"}>{t("account.add.one-time-transfer.text.label.payment_purpose")}</label>
                    <select
                        class={"form-select"}
                        id="choose-one-time-transfer-purpose-select"
                        onInput={(e) => setSelectedPurposeID((e.target as HTMLSelectElement).value)}
                    >
                        <option value="-1"
                                selected>{t("account.add.one-time-transfer.text.select.choose_purpose")}</option>
                        {transferPurposes && transferPurposes.map((purpose) => {
                            return <option value={purpose.id}>{purpose.title}</option>
                        })}
                    </select>
                </div>
                <div class={"mb-3"}>
                    <label for="one-time-transfer-amount-input"
                           class={"form-label"}>{t("account.add.one-time-transfer.text.label.amount", {currency: accountCurrency})}</label>
                    <input
                        type="number"
                        class={"form-control"}
                        id="one-time-transfer-amount-input"
                        step="1"
                        min="1"
                        onInput={(e) => setAmount(parseInt((e.target as HTMLInputElement).value))}
                        placeholder={t("account.add.one-time-transfer.text.placeholder.enter_amount")}
                    />
                </div>
                <button
                    type="submit"
                    class={"btn btn-info"}
                    disabled={!Number.isInteger(amount) || amount < 1 || selectedPurposeID === "-1" || selectedDstAccountID === "-1"}
                >
                    {t("account.add.one-time-transfer.btn.add.text")}
                </button>
                &nbsp;
                <button
                    type="button"
                    class={"btn btn-secondary"}
                    onClick={() => route("/accounts", true)}
                >
                    {t("account.add.one-time-transfer.btn.cancel.text")}
                </button>
            </form>
        </div>
    );
}

export default AddOneTimeTransfer;
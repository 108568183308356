const accountsOverview = {
    en: {
        translation: {
            // Accounts overview translations
            "accounts.overview.headline": "Accounts",
            "accounts.overview.no_accounts_found": "No accounts found",
            "accounts.overview.loading_accounts": "Loading accounts...",
            "accounts.overview.error_fetching": "Error fetching accounts: {{errorMessage}}",

            // Create Account Form translations
            "accounts.overview.create-account-form.title_placeholder": "Enter account title (min. {{minLength}} characters)",
            "accounts.overview.create-account-form.title_err_len": "The title must be at least {{minLength}} characters long.",
            "accounts.overview.create-account-form.create_btn_text": "Create Account",
            "accounts.overview.create-account-form.error_message": "Error creating account: {{errorMessage}}",

            // Account Card translations
            "accounts.overview.account-card.expenses": "Expenses",
            "accounts.overview.account-card.incomes": "Incomes",
            "accounts.overview.account-card.sent_transfer": "Sent Transfers",
            "accounts.overview.account-card.received_transfer": "Received Transfers",
            "accounts.overview.account-card.add_income": "Add Income",
            "accounts.overview.account-card.add_expense": "Add Expense",
            "accounts.overview.account-card.transfer": "Transfer",
            "accounts.overview.account-card.view_details": "View Details"
        }
    },
    de: {
        translation: {
            // Accounts overview translations
            "accounts.overview.headline": "Konten",
            "accounts.overview.no_accounts_found": "Keine Konten gefunden",
            "accounts.overview.loading_accounts": "Konten werden geladen...",
            "accounts.overview.error_fetching": "Fehler beim Abrufen der Konten: {{errorMessage}}",

            // Create Account Form translations
            "accounts.overview.create-account-form.title_placeholder": "Kontotitel eingeben (mind. {{minLength}} Zeichen)",
            "accounts.overview.create-account-form.title_err_len": "Der Titel muss mindestens {{minLength}} Zeichen lang sein.",
            "accounts.overview.create-account-form.create_btn_text": "Konto erstellen",
            "accounts.overview.create-account-form.error_message": "Fehler beim Erstellen des Kontos: {{errorMessage}}",

            // Account Card translations
            "accounts.overview.account-card.expenses": "Ausgaben",
            "accounts.overview.account-card.incomes": "Einnahmen",
            "accounts.overview.account-card.sent_transfer": "Gesendete Übertragungen",
            "accounts.overview.account-card.received_transfer": "Empfangene Übertragungen",
            "accounts.overview.account-card.add_income": "Einnahme hinzufügen",
            "accounts.overview.account-card.add_expense": "Ausgabe hinzufügen",
            "accounts.overview.account-card.transfer": "Übertragen",
            "accounts.overview.account-card.view_details": "Details ansehen"
        }
    },
    ru: {
        translation: {
            // Accounts overview translations
            "accounts.overview.headline": "Счета",
            "accounts.overview.no_accounts_found": "Счета не найдены",
            "accounts.overview.loading_accounts": "Загрузка счетов...",
            "accounts.overview.error_fetching": "Ошибка при получении счетов: {{errorMessage}}",

            // Create Account Form translations
            "accounts.overview.create-account-form.title_placeholder": "Введите название счета (мин. {{minLength}} символов)",
            "accounts.overview.create-account-form.title_err_len": "Название должно быть не менее {{minLength}} символов.",
            "accounts.overview.create-account-form.create_btn_text": "Создать счет",
            "accounts.overview.create-account-form.error_message": "Ошибка при создании счета: {{errorMessage}}",

            // Account Card translations
            "accounts.overview.account-card.expenses": "Расходы",
            "accounts.overview.account-card.incomes": "Доходы",
            "accounts.overview.account-card.sent_transfer": "Отправленные переводы",
            "accounts.overview.account-card.received_transfer": "Полученные переводы",
            "accounts.overview.account-card.add_income": "Добавить доход",
            "accounts.overview.account-card.add_expense": "Добавить расход",
            "accounts.overview.account-card.transfer": "Перевод",
            "accounts.overview.account-card.view_details": "Посмотреть детали"
        }
    }
};

export { accountsOverview };
import {FunctionalComponent, h} from "preact";
import genericStyle from "../style.css";
import {AccountServiceClient} from "../../../generated/service/AccountServiceClientPb";
import {AccountOverview, FetchAccountsOverviewQueryRequest} from "../../../generated/query/account_pb";
import {useEffect} from "preact/compat";
import {useState} from "preact/hooks";
import AccountCard from "./AccountCard";
import CreateAccountForm from "./CreateAccountForm";
import {formatCurrencyFromCents} from "../../../utils/formatting";
import {useTranslation} from "react-i18next";
import TitleBackBtn from "../../../components/button/TitleBackBtn";

/**
 * Accounts component properties.
 */
interface AccountsProps {
    accountServiceClient: AccountServiceClient;
}

/**
 * Wrapped account interface.
 */
interface WrappedAccount {
    id: string;
    accountOverview: AccountOverview;
}

/**
 * Accounts component.
 */
const Accounts: FunctionalComponent<AccountsProps> = ({accountServiceClient}) => {
    const {t} = useTranslation();

    const [totalFetchedAccounts, setTotalFetchedAccounts] = useState<number>(0);
    const [fetchedAndSortedAccounts, setFetchedAndSortedAccounts] = useState<Array<WrappedAccount>>([]);

    /**
     * Fetches accounts from the server on component mount.
     */
    useEffect(() => {
        fetchAccounts()
            .catch((err) => {
                console.error(t("accounts.overview.error_fetching", {errorMessage: err.message}));
                throw err;
            });
    }, []);

    /**
     * Fetches accounts from the server and sets the state accordingly.
     */
    const fetchAccounts = async () => {
        const response = await accountServiceClient.fetchAccountsOverview(new FetchAccountsOverviewQueryRequest());
        setTotalFetchedAccounts(response.getTotal());

        // create sorted array of wrapped accounts by ascending title
        const sortedAccounts: WrappedAccount[] = [];
        response.getAccountsMap().forEach((accountOverview: AccountOverview, accountID: string) => {
            const wrappedAccount: WrappedAccount = {id: accountID, accountOverview};
            sortedAccounts.push(wrappedAccount);
        });

        sortedAccounts.sort((a: WrappedAccount, b: WrappedAccount) => {
            return a.accountOverview.getTitle().localeCompare(b.accountOverview.getTitle());
        });

        setFetchedAndSortedAccounts(sortedAccounts);
    };

    /**
     * Renders the fetched accounts.
     */
    const renderAccounts = () => {
        if (totalFetchedAccounts === 0) {
            return <div style="text-align: center;">{t("accounts.overview.no_accounts_found")}</div>;
        }

        return fetchedAndSortedAccounts.map((wrappedAccount: WrappedAccount) => {
            const account = wrappedAccount.accountOverview;

            const createdAt = new Date(
                account.getCreatedat().getSeconds() * 1000 +
                account.getCreatedat().getNanos() / 1000000
            );

            const isBalanceNegative = account.getBalance().getNegative();

            let balanceAmount = account.getBalance().getAmount();
            if (isBalanceNegative) {
                balanceAmount *= -1;
            }

            const balance = formatCurrencyFromCents(balanceAmount, account.getBalance().getCurrency());

            return <AccountCard
                account={account}
                accountID={wrappedAccount.id}
                createdAt={createdAt}
                balance={balance}
                isBalanceNegative={isBalanceNegative}
            />;
        });
    };

    /**
     * Renders the component.
     */
    return (
        <div class={"container-lg"}>
            <TitleBackBtn title={t("accounts.overview.headline")} canGoBack={false}/>
            <CreateAccountForm
                accountServiceClient={accountServiceClient}
                successCallback={fetchAccounts}
            />
            {fetchedAndSortedAccounts ? renderAccounts() : <p>{t("accounts.overview.loading_accounts")}</p>}
        </div>
    );
};

export default Accounts;
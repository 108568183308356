const transactionPurposes = {
    en: {
        translation: {
            // Payment Purposes translations
            "payment-purposes.headline": "Payment Purposes",
            "payment-purposes.no_purposes_found": "No payment purposes found.",
            "payment-purposes.loading_purposes": "Loading payment purposes...",
            "payment-purposes.error_fetching": "Error fetching payment purposes: {{errorMessage}}",

            // Create Purpose Form translations
            "payment-purposes.create-purpose-form.title_placeholder": "Enter new payment purpose title (min. {{minLength}} characters)",
            "payment-purposes.create-purpose-form.title_err_len": "Payment purpose title must be at least {{minLength}} characters long.",
            "payment-purposes.create-purpose-form.create_btn_text": "Create New Payment Purpose",
            "payment-purposes.create-purpose-form.error_message": "Error creating payment purpose: {{errorMessage}}"
        }
    },
    de: {
        translation: {
            // Payment Purposes translations
            "payment-purposes.headline": "Zahlungszwecke",
            "payment-purposes.no_purposes_found": "Keine Zahlungszwecke gefunden.",
            "payment-purposes.loading_purposes": "Zahlungszwecke werden geladen...",
            "payment-purposes.error_fetching": "Fehler beim Abrufen der Zahlungszwecke: {{errorMessage}}",

            // Create Purpose Form translations
            "payment-purposes.create-purpose-form.title_placeholder": "Neuen Zahlungszweck eingeben (mind. {{minLength}} Zeichen)",
            "payment-purposes.create-purpose-form.title_err_len": "Der Zahlungszweck muss mindestens {{minLength}} Zeichen lang sein.",
            "payment-purposes.create-purpose-form.create_btn_text": "Neuen Zahlungszweck erstellen",
            "payment-purposes.create-purpose-form.error_message": "Fehler beim Erstellen des Zahlungszwecks: {{errorMessage}}"
        }
    },
    ru: {
        translation: {
            // Payment Purposes translations
            "payment-purposes.headline": "Цели платежей",
            "payment-purposes.no_purposes_found": "Цели платежей не найдены.",
            "payment-purposes.loading_purposes": "Загрузка целей платежей...",
            "payment-purposes.error_fetching": "Ошибка при получении целей платежей: {{errorMessage}}",

            // Create Purpose Form translations
            "payment-purposes.create-purpose-form.title_placeholder": "Введите новую цель платежа (мин. {{minLength}} символов)",
            "payment-purposes.create-purpose-form.title_err_len": "Название цели платежа должно быть не менее {{minLength}} символов.",
            "payment-purposes.create-purpose-form.create_btn_text": "Создать новую цель платежа",
            "payment-purposes.create-purpose-form.error_message": "Ошибка при создании цели платежа: {{errorMessage}}"
        }
    }
};

export { transactionPurposes };
import {useUser} from "../../../auth/UserProvider";
import {Fragment, FunctionalComponent, h} from "preact";
import ProtectedNavigation from "./protected";
import PublicNavigation from "./public";

const MainNavigation: FunctionalComponent = () => {
    const {user} = useUser();

    return (
        <Fragment>
            {user && user.isAuthenticated ? (<ProtectedNavigation/>) : (<PublicNavigation/>)}
        </Fragment>
    )
};

export default MainNavigation;
import {h} from "preact";
import UserVO from "./UserVO";
import {createContext, FunctionComponent} from "preact";
import {useState, useContext} from "preact/hooks";
import AuthClientService from "./AuthClientService";
import {useEffect} from "preact/compat";
import NotAuthenticatedError from "./NotAuhtenticatedError";

interface UserContextProps {
    user: UserVO | null;
    setUser: (user: UserVO | null) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
    authClient: AuthClientService;
}

const UserProvider: FunctionComponent<UserProviderProps> = ({ children, authClient }) => {
    const [user, setUser] = useState<UserVO | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAndSetUser = async () => {
            try {
                const idToken = await authClient.fetchID();
                const user = new UserVO(idToken.displayName, idToken.authExpiresAt);
                setUser(user);
            } catch (error) {
                if (error instanceof NotAuthenticatedError) {
                    setUser(null);
                    return;
                }
                setUser(null);
            } finally {
                setLoading(false);
            }
        }

        fetchAndSetUser().finally(() => setLoading(false));
    }, [authClient]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <UserContext.Provider value={{user, setUser}}>
            {children}
        </UserContext.Provider>
    );
};

const useUser = (): UserContextProps => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useUser must be used within a UserProvider");
    }

    return context;
}

export default UserProvider;
export {useUser};

const navigation = {
    en: {
        translation: {
            "navigation.item.accounts": "Accounts",
            "navigation.item.tx_purposes": "Payment Purposes",
            "navigation.item.logout": "Logout",
            "navigation.text.logged_in_as": "Logged in as"
        }
    },
    de: {
        translation: {
            "navigation.item.accounts": "Konten",
            "navigation.item.tx_purposes": "Zahlungszwecke",
            "navigation.item.logout": "Abmelden",
            "navigation.text.logged_in_as": "Angemeldet als"
        }
    },
    ru: {
        translation: {
            "navigation.item.accounts": "Счета",
            "navigation.item.tx_purposes": "Назначения платежей",
            "navigation.item.logout": "Выйти",
            "navigation.text.logged_in_as": "Вы вошли как"
        }
    }
};

export { navigation };
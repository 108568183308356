class UserVO {
    private readonly _displayName: string;
    private readonly _authExpiresAt: Date;

    constructor(displayName: string, authExpiresAt: Date) {
        this._displayName = displayName;
        this._authExpiresAt = authExpiresAt;
    }

    public get displayName(): string {
        return this._displayName;
    }

    public get isAuthenticated(): boolean {
        return new Date() < this._authExpiresAt;
    }
}

export default UserVO;
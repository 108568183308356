import IDToken from "./IDTokenDTO";
import NotAuthenticatedError from "./NotAuhtenticatedError";

class AuthClientService {
    public async fetchID(): Promise<IDToken> {
        const response = await fetch("/api/fetch-id", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Unauthorized status code
                throw new NotAuthenticatedError(); // Throw the custom error
            } else {
                throw new Error("Failed to fetch ID."); // Generic error for other status codes
            }
        }

        const data = await response.json();
        const authExpiresAt = new Date(data.auth_expires_at * 1000);

        return new IDToken(data.display_name, authExpiresAt);
    }

    public async signIn(email: string, password: string): Promise<IDToken> {
        const response = await fetch("/api/sign-in", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
            body: JSON.stringify({
                origin_id: email,
                password: password,
            }),
        });

        if (response.status === 401) {
            throw new Error("Invalid email or password.");
        } else if (!response.ok) {
            throw new Error("An error occurred. Please try again later.");
        }

        const {id_token} = await response.json();
        return new IDToken(
            id_token.display_name,
            new Date(id_token.auth_expires_at * 1000)
        );
    }

    public async signOut(): Promise<void> {
        await fetch("/api/sign-out", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
        });
    }
}

export default AuthClientService;

import {FunctionComponent, h, JSX} from "preact";
import {CreateAccountCommandRequest} from "../../../generated/command/account_pb";
import {useState} from "preact/hooks";
import {AccountServiceClient} from "../../../generated/service/AccountServiceClientPb";
import {useTranslation} from "react-i18next";

/**
 * Create Account Form component properties.
 */
interface CreateAccountFormProps {
    accountServiceClient: AccountServiceClient;
    successCallback: () => void;
}

/**
 * Create Account Form component.
 */
const CreateAccountForm: FunctionComponent<CreateAccountFormProps> = ({accountServiceClient, successCallback}) => {
    const {t} = useTranslation();

    const newAccountTitleMinLength = 5;

    const [newAccountTitle, setNewAccountTitle] = useState<string>('');
    const [createAccountError, setCreateAccountError] = useState<string>('');

    /**
     * Handles the form submission to create a new account.
     * @param e The form submission event.
     */
    const handleCreateAccount = (e: JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newAccountTitle.length < newAccountTitleMinLength) {
            setCreateAccountError(t('accounts.overview.create-account-form.title_err_len', {minLength: newAccountTitleMinLength}));
            return;
        }

        const createAccount = async () => {
            const cmd = new CreateAccountCommandRequest();
            cmd.setTitle(newAccountTitle);
            cmd.setIso4217currencycode('EUR');

            await accountServiceClient.createAccount(cmd);
        }

        createAccount().then(() => {
            setCreateAccountError('');
        }).catch((err) => {
            setCreateAccountError(t('accounts.overview.create-account-form.error_message', { errorMessage: err.message }));

            throw err;
        }).finally(() => {
            setNewAccountTitle('');
            successCallback();
        });
    }

    return (
        <form onSubmit={handleCreateAccount}>
            <div class="row mb-5">
                <input type="text"
                       placeholder={t('accounts.overview.create-account-form.title_placeholder', {minLength: newAccountTitleMinLength})}
                       class={"form-control mb-2"}
                       value={newAccountTitle}
                       onInput={(e) => {
                           setCreateAccountError('');
                           setNewAccountTitle((e.target as HTMLInputElement).value);
                       }}
                       required/>
                {createAccountError && (
                    <div class="alert alert-danger" role="alert">
                        {createAccountError}
                    </div>
                )}
                <select class={"form-control mb-2"}
                        disabled
                        required>
                    <option value="EUR">EUR</option>
                </select>
                <button class={"btn btn-primary"}
                        type={"submit"}
                        disabled={!newAccountTitle}>
                    {t('accounts.overview.create-account-form.create_btn_text')}
                </button>
            </div>
        </form>
    )
}

export default CreateAccountForm;
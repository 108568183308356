import {FunctionalComponent, h, JSX} from "preact";
import {AccountServiceClient} from "../../../../../generated/service/AccountServiceClientPb";
import genericStyle from "../../../style.css";
import {useState} from "preact/hooks";
import {useEffect} from "preact/compat";
import {
    FetchAccountDetailsQueryRequest,
    FetchAccountDetailsQueryResponse,
    FetchedAccountDetailsLevel
} from "../../../../../generated/query/account_pb";
import {TransactionServiceClient} from "../../../../../generated/service/TransactionServiceClientPb";
import {
    FetchTransactionPurposesQueryRequest,
    FetchTransactionPurposesQueryResponse
} from "../../../../../generated/query/transaction_pb";
import {AddOneTimeExpenseCommandRequest, Transaction} from "../../../../../generated/command/account_pb";
import {formatCurrencyFromCents} from "../../../../../utils/formatting";
import {route} from "preact-router";
import {useTranslation} from "react-i18next";
import TitleBackBtn from "../../../../../components/button/TitleBackBtn";

/**
 * Add One Time Expense component properties.
 */
interface AddOneTimeExpenseProps {
    txServiceClient: TransactionServiceClient;
    accountServiceClient: AccountServiceClient;
    accountID: string;
}

/**
 * Expense purpose interface.
 */
interface ExpensePurpose {
    id: string;
    title: string;
}

/**
 * Add One Time Expense component.
 */
const AddOneTimeExpense: FunctionalComponent<AddOneTimeExpenseProps> = (
    {
        txServiceClient,
        accountServiceClient,
        accountID
    }) => {
    const {t} = useTranslation();

    const [accountTitle, setAccountTitle] = useState<string>("");
    const [accountCurrency, setAccountCurrency] = useState<string>("");
    const [expensePurposes, setExpensePurposes] = useState<Array<ExpensePurpose>>([]);
    const [amount, setAmount] = useState<number>(0);
    const [selectedPurposeID, setSelectedPurposeID] = useState<string>("-1");
    const [addExpenseError, setAddExpenseError] = useState<string>("");
    const [addExpenseSuccess, setAddExpenseSuccess] = useState<boolean>(false);

    /**
     * Fetches account details from the server on component mount.
     */
    useEffect(() => {
        fetchAccountDetails()
            .catch((err) => {
                setAddExpenseSuccess(false);
                setAddExpenseError(err.message);

                throw err;
            })
            .then((response: FetchAccountDetailsQueryResponse) => {
                setAccountTitle(response.getTitle());
                setAccountCurrency(response.getCurrency());
            });

        fetchExpensePurposes()
            .catch((err) => {
                setAddExpenseSuccess(false);
                setAddExpenseError(err.message);

                throw err;
            })
            .then((response: FetchTransactionPurposesQueryResponse) => {
                const expensePurposes: Array<ExpensePurpose> = response.getPurposesList().map((purpose) => {
                    return {id: purpose.getId(), title: purpose.getTitle()};
                });

                expensePurposes.sort((a: ExpensePurpose, b: ExpensePurpose) => {
                    return a.title.localeCompare(b.title);
                });

                setExpensePurposes(expensePurposes);
            });
    }, []);

    /**
     * Fetches expense purposes from the server.
     */
    const fetchExpensePurposes = async () => {
        const fetchExpensePurposesRequest = new FetchTransactionPurposesQueryRequest();
        return txServiceClient.fetchTransactionPurposes(fetchExpensePurposesRequest);
    }

    /**
     * Fetches account details from the server.
     */
    const fetchAccountDetails = async () => {
        const fetchDetailsRequest = new FetchAccountDetailsQueryRequest();
        fetchDetailsRequest.setAccountid(accountID);
        fetchDetailsRequest.setDetailslevel(FetchedAccountDetailsLevel.FETCHED_ACCOUNT_DETAILS_LEVEL_MINIMAL);

        return accountServiceClient.fetchAccountDetails(fetchDetailsRequest);
    }

    /**
     * Handles the form submission.
     * @param e
     */
    const handleAddOneTimeExpense = (e: JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        const tx = new Transaction();
        tx.setAccountid(accountID);
        tx.setAmount(amount);
        tx.setPurposeid(selectedPurposeID);

        const cmd = new AddOneTimeExpenseCommandRequest();
        cmd.setTransaction(tx);

        accountServiceClient.addOneTimeExpense(cmd)
            .catch((err) => {
                setAddExpenseSuccess(false);
                setAddExpenseError(err.message);

                throw err;
            })
            .then(() => {
                setAddExpenseSuccess(true);
            });
    }

    /**
     * Renders the component.
     */
    return (
        <div class={"container-lg"}>
            <TitleBackBtn title={t("account.add.one-time-expense.headline")} canGoBack={true}/>
            {addExpenseError && <div class={"alert alert-danger"}>{addExpenseError}</div>}
            {addExpenseSuccess && <div class={"alert alert-success"}>{t("account.add.one-time-expense.text.success", {amount: formatCurrencyFromCents(amount, accountCurrency)})}</div>}
            <form onSubmit={handleAddOneTimeExpense}>
                <div class={"mb-3"}>
                    <label for="account-title-input" class={"form-label"}>{t("account.add.one-time-expense.text.label.account")}</label>
                    <input type="text" class={"form-control"} id="account-title-input" value={accountTitle} disabled/>
                </div>
                <div class={"mb-3"}>
                    <label for="choose-one-time-expense-purpose-select" class={"form-label"}>{t("account.add.one-time-expense.text.label.payment_purpose")}</label>
                    <select
                        class={"form-select"}
                        id="choose-one-time-expense-purpose-select"
                        onInput={(e) => setSelectedPurposeID((e.target as HTMLSelectElement).value)}
                    >
                        <option value="-1" selected>{t("account.add.one-time-expense.text.select.choose")}</option>
                        {expensePurposes && expensePurposes.map((purpose) => {
                            return <option value={purpose.id}>{purpose.title}</option>
                        })}
                    </select>
                </div>
                <div class={"mb-3"}>
                    <label for="one-time-expense-amount-input" class={"form-label"}>{t("account.add.one-time-expense.text.label.amount", {currency: accountCurrency})}</label>
                    <input
                        type="number"
                        class={"form-control"}
                        id="one-time-expense-amount-input"
                        step="1"
                        min="1"
                        onInput={(e) => setAmount(parseInt((e.target as HTMLInputElement).value))}
                        placeholder={t("account.add.one-time-expense.text.placeholder.enter_amount")}
                    />
                </div>
                <button
                    type="submit"
                    class={"btn btn-danger"}
                    disabled={!Number.isInteger(amount) || amount < 1 || selectedPurposeID === "-1"}
                >
                    {t("account.add.one-time-expense.btn.add.text")}
                </button>
                &nbsp;
                <button
                    type="button"
                    class={"btn btn-secondary"}
                    onClick={() => route("/accounts", true)}
                >
                    {t("account.add.one-time-expense.btn.cancel.text")}
                </button>
            </form>
        </div>
    );
}

export default AddOneTimeExpense;
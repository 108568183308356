const accountFetchDetails = {
    en: {
        translation: {
            "account.fetch-details.headline": "Account Details",
            "account.fetch-details.label.created_at": "Created At",
            "account.fetch-details.label.balance": "Balance",
            "account.fetch-details.label.ote_title": "One-Time Expenses",
            "account.fetch-details.label.oti_title": "One-Time Incomes",
            "account.fetch-details.label.sent_transfers_title": "Sent Transfers",
            "account.fetch-details.label.received_transfers_title": "Received Transfers",
            "account.fetch-details.text.nothing": "Nothing available",
            "account.fetch-details.text.loading": "Loading..."
        }
    },
    de: {
        translation: {
            "account.fetch-details.headline": "Kontodetails",
            "account.fetch-details.label.created_at": "Erstellt am",
            "account.fetch-details.label.balance": "Kontostand",
            "account.fetch-details.label.ote_title": "Einmalige Ausgaben",
            "account.fetch-details.label.oti_title": "Einmalige Einnahmen",
            "account.fetch-details.label.sent_transfers_title": "Gesendete Übertragungen",
            "account.fetch-details.label.received_transfers_title": "Erhaltene Übertragungen",
            "account.fetch-details.text.nothing": "Nichts verfügbar",
            "account.fetch-details.text.loading": "Laden..."
        }
    },
    ru: {
        translation: {
            "account.fetch-details.headline": "Детали счета",
            "account.fetch-details.label.created_at": "Создано",
            "account.fetch-details.label.balance": "Баланс",
            "account.fetch-details.label.ote_title": "Разовые расходы",
            "account.fetch-details.label.oti_title": "Разовые доходы",
            "account.fetch-details.label.sent_transfers_title": "Отправленные переводы",
            "account.fetch-details.label.received_transfers_title": "Полученные переводы",
            "account.fetch-details.text.nothing": "Ничего нет",
            "account.fetch-details.text.loading": "Загрузка..."
        }
    }
};

export { accountFetchDetails };
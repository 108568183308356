const login = {
    en: {
        translation: {
            "login.headline": "Sign in",
            "login.error.text.not_both_fields": "Please fill in both fields.",
            "login.text.label.email_address": "E-Mail address",
            "login.text.autocomplete.email_address": "your@email.address",
            "login.text.label.password": "Password",
            "login.text.autocomplete.password": "your password",
            "login.btn.sign_in.text": "Sign in",
            "login.btn.cancel.text": "Cancel",
        }
    },
    de: {
        translation: {
            "login.headline": "Anmelden",
            "login.error.text.not_both_fields": "Bitte füllen Sie beide Felder aus.",
            "login.text.label.email_address": "E-Mail-Adresse",
            "login.text.autocomplete.email_address": "deine@email.adresse",
            "login.text.label.password": "Passwort",
            "login.text.autocomplete.password": "dein Passwort",
            "login.btn.sign_in.text": "Anmelden",
            "login.btn.cancel.text": "Abbrechen",
        }
    },
    ru: {
        translation: {
            "login.headline": "Войти",
            "login.error.text.not_both_fields": "Пожалуйста, заполните оба поля.",
            "login.text.label.email_address": "Адрес электронной почты",
            "login.text.autocomplete.email_address": "ваш@почта.адрес",
            "login.text.label.password": "Пароль",
            "login.text.autocomplete.password": "ваш пароль",
            "login.btn.sign_in.text": "Войти",
            "login.btn.cancel.text": "Отмена",
        }
    }
};

export { login };
import {FunctionalComponent, h} from "preact";
import style from "./style.css";
import {AccountServiceClient} from "../../../../../generated/service/AccountServiceClientPb";
import {useState} from "preact/hooks";
import {
    FetchAccountDetailsQueryRequest,
    FetchAccountDetailsQueryResponse,
    FetchedAccountDetailsLevel,
    SummarizedAccountTransaction,
} from "../../../../../generated/query/account_pb";
import {useEffect} from "preact/compat";
import {formatCurrencyFromCents, formatDate} from "../../../../../utils/formatting";
import {useTranslation} from "react-i18next";
import TitleBackBtn from "../../../../../components/button/TitleBackBtn";

interface FetchDetailsProps {
    accountServiceClient: AccountServiceClient;
    accountID: string;
}

const FetchDetails: FunctionalComponent<FetchDetailsProps> = ({accountServiceClient, accountID}) => {
    const {t} = useTranslation();
    const [fetchedDetails, setFetchedDetails] = useState<FetchAccountDetailsQueryResponse>(null);
    const [error, setError] = useState<string>("");

    useEffect(() => {
        fetchDetails().catch((error) => {
            setError(error.message);
        });
    }, []);

    const fetchDetails = async () => {
        const request = new FetchAccountDetailsQueryRequest();
        request.setAccountid(accountID);
        request.setDetailslevel(FetchedAccountDetailsLevel.FETCHED_ACCOUNT_DETAILS_LEVEL_FULL);
        const response = await accountServiceClient.fetchAccountDetails(request);
        setFetchedDetails(response);
    };

    const renderTransactionList = (transactionList: SummarizedAccountTransaction[]) => {
        if (transactionList.length === 0) {
            return null;
        }

        return (
            <div class={`${style.transactionList}`}>
                {transactionList.map((transaction) => (
                    <div class={`${style.transactionItem}`}>
                    <span class={`${style.transactionPurpose}`}>
                        {transaction.getPurposetitle()}
                    </span>
                        <span class={`${style.transactionCount}`}>
                        {transaction.getTxcount()}
                    </span>
                        <span class={`${style.transactionAmount}`}>
                        {formatCurrencyFromCents(transaction.getTxamount(), transaction.getCurrency())}
                    </span>
                    </div>
                ))}
            </div>
        );
    };

    const renderBalance = () => {
        const balance = fetchedDetails.getBalance();
        const amount = balance.getNegative() ? -balance.getAmount() : balance.getAmount();
        const formattedBalance = formatCurrencyFromCents(amount, balance.getCurrency());
        const balanceClass = balance.getNegative() ? style.negativeBalance : style.positiveBalance;

        return <div class={`${style.balance} ${balanceClass}`}>{formattedBalance}</div>;
    };

    const renderSection = (title: string, transactionList: SummarizedAccountTransaction[]) => {
        if (transactionList.length === 0) {
            return null;
        }

        return (
            <div class={`${style.section}`}>
                <h5>{title}</h5>
                {renderTransactionList(transactionList)}
            </div>
        );
    };

    const renderDetails = () => {
        if (!fetchedDetails) {
            return <div>{t("account.fetch-details.text.loading")}</div>;
        }

        const createdAt = new Date(
            fetchedDetails.getCreatedat().getSeconds() * 1000 +
            fetchedDetails.getCreatedat().getNanos() / 1000000
        );

        return (
            <div class={`${style.detailsContent}`}>
                <div class={`${style.detailItem}`}>
                    <span class={`${style.label}`}>{t("account.fetch-details.label.created_at")}</span>
                    <span class={`${style.value}`}>{formatDate(createdAt)}</span>
                </div>
                <div class={`${style.detailItem}`}>
                    <span class={`${style.label}`}>{t("account.fetch-details.label.balance")}</span>
                    {renderBalance()}
                </div>
                {renderSection(
                    t("account.fetch-details.label.ote_title"),
                    fetchedDetails.getSummarizedonetimeexpensesList()
                )}
                {renderSection(
                    t("account.fetch-details.label.oti_title"),
                    fetchedDetails.getSummarizedonetimeincomesList()
                )}
                {renderSection(
                    t("account.fetch-details.label.sent_transfers_title"),
                    fetchedDetails.getSummarizedsenttransfersList()
                )}
                {renderSection(
                    t("account.fetch-details.label.received_transfers_title"),
                    fetchedDetails.getSummarizedreceivedtransfersList()
                )}
            </div>
        );
    };

    return (
        <div class={"container-lg"}>
            <TitleBackBtn title={fetchedDetails && fetchedDetails.getTitle()} canGoBack={true}/>
            {error && <div class="alert alert-danger" role="alert">{error}</div>}
            {renderDetails()}
        </div>
    );
};

export default FetchDetails;
import i18n from "../i18n";

/**
 * Formats a currency amount from cents to the user's locale.
 * @param cents The amount in cents.
 * @param currency The currency code.
 * @returns The formatted currency string.
 */
const formatCurrencyFromCents = (cents: number, currency: string = 'EUR'): string => {
    const amountInUnits = cents / 100;

    return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: currency,
    }).format(amountInUnits);
};

function formatDate(date: Date): string {
    return new Intl.DateTimeFormat(i18n.language, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    }).format(date);
}

export {formatCurrencyFromCents, formatDate};
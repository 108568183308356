import { FunctionComponent, h } from "preact";
import style from "./style.css";
import genericStyle from "../style.css";
import { TransactionServiceClient } from "../../../generated/service/TransactionServiceClientPb";
import { useState, useEffect } from "preact/hooks";
import { FetchedTransactionPurpose, FetchTransactionPurposesQueryRequest } from "../../../generated/query/transaction_pb";
import CreatePurposeForm from "./CreatePurposeForm";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../utils/formatting";
import TitleBackBtn from "../../../components/button/TitleBackBtn";

interface TxPurposesProps {
    txServiceClient: TransactionServiceClient;
}

const TxPurposes: FunctionComponent<TxPurposesProps> = ({ txServiceClient }) => {
    const { t } = useTranslation();
    const [fetchedTxPurposes, setFetchedTxPurposes] = useState<Array<FetchedTransactionPurpose>>([]);
    const [totalFetchedTxPurposes, setTotalFetchedTxPurposes] = useState<number>(0);

    useEffect(() => {
        fetchTxPurposes().catch((err) => {
            console.error(t("payment-purposes.error_fetching", { errorMessage: err.message }));
            throw err;
        });
    }, []);

    const fetchTxPurposes = async () => {
        const response = await txServiceClient.fetchTransactionPurposes(new FetchTransactionPurposesQueryRequest());
        setTotalFetchedTxPurposes(response.getTotal());

        setFetchedTxPurposes(response.getPurposesList().sort((a, b) => {
            return a.getTitle().localeCompare(b.getTitle());
        }));
    };

    const renderTxPurposes = () => {
        if (totalFetchedTxPurposes === 0) {
            return <div class="text-center">{t("payment-purposes.no_purposes_found")}</div>;
        }

        return fetchedTxPurposes.map((purpose, index) => {
            const createdAt = new Date(
                purpose.getCreatedat().getSeconds() * 1000 +
                purpose.getCreatedat().getNanos() / 1000000
            );

            return (
                <div
                    class={`row py-4 mb-4 ${style.rowTransactionPurpose} ${index % 2 === 0 ? style.evenRow : style.oddRow}`}
                >
                    <div class="col-lg-6 mb-4">
                        <h2 class={style.title}>{purpose.getTitle()}</h2>
                    </div>
                    <div class="col-lg-6">
                        <div class={style.value}>{formatDate(createdAt)}</div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div class={"container-lg"}>
            <TitleBackBtn title={t("payment-purposes.headline")} canGoBack={true}/>
            <CreatePurposeForm txServiceClient={txServiceClient} successCallback={fetchTxPurposes}/>
            {fetchedTxPurposes ? renderTxPurposes() : <p>{t("payment-purposes.loading_purposes")}</p>}
        </div>
    );
};

export default TxPurposes;
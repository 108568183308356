import { FunctionComponent, h } from "preact";
import style from "./style.css";
import { AccountOverview } from "../../../generated/query/account_pb";
import { useTranslation } from "react-i18next";

interface AccountCardProps {
    account: AccountOverview;
    accountID: string;
    createdAt: Date;
    balance: string;
    isBalanceNegative: boolean;
}

/**
 * Account card component.
 */
const AccountCard: FunctionComponent<AccountCardProps> = ({
                                                              account,
                                                              accountID,
                                                              balance,
                                                              isBalanceNegative,
                                                          }) => {
    const { t } = useTranslation();

    return (
        <div class={`row py-4 mb-4 ${style.rowAccount}`}>
            {/* Left Column */}
            <div class="col-md-6 mb-4">
                <div class="row">
                    <div class="col">
                        <h2 class={style.title}>{account.getTitle()}</h2>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div
                            class={`${style.value} ${style.valueBalance} ${isBalanceNegative ? style.negativeBalance : style.positiveBalance}`}>
                            {balance}
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Column with Action Buttons */}
            <div
                class="col-md-6 d-flex flex-row flex-md-column align-items-center align-items-md-stretch justify-content-center">
                <ActionButton
                    href={`/accounts/${accountID}/add/one-time-income`}
                    icon="fas fa-plus"
                    label={t("accounts.overview.account-card.add_income")}
                />
                <ActionButton
                    href={`/accounts/${accountID}/add/one-time-expense`}
                    icon="fas fa-minus"
                    label={t("accounts.overview.account-card.add_expense")}
                />
                <ActionButton
                    href={`/accounts/${accountID}/add/one-time-transfer`}
                    icon="fas fa-exchange-alt"
                    label={t("accounts.overview.account-card.transfer")}
                />
                <ActionButton
                    href={`/accounts/${accountID}/fetch/details`}
                    icon="fas fa-eye"
                    label={t("accounts.overview.account-card.view_details")}
                />
            </div>
        </div>
    );
};

/**
 * ActionButton component for rendering individual buttons with icons and labels.
 */
const ActionButton: FunctionComponent<{ href: string; icon: string; label: string }> = ({ href, icon, label }) => (
    <a class={`btn ${style.btnDarkGrayCustom} mx-1 my-md-2 w-100`} href={href} title={label}>
        <i class={icon}></i>
        <span class="d-none d-md-inline ms-2">{label}</span>
    </a>
);

export default AccountCard;
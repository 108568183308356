import {FunctionalComponent, h} from "preact";
import {useState} from "preact/hooks";
import style from "./style.css";
import i18n from "../../../i18n";

/**
 * LanguageSwitcher component
 */
const LanguageSwitcher: FunctionalComponent = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState<string>(() => {
        const setLang = i18n.language;

        const splitLang = setLang.split('-');
        if (splitLang.length > 1) {
            return `${splitLang[0].toUpperCase()}`;
        }

        return setLang.toUpperCase();
    });

    /**
     * Apply the selected language
     * @param lang
     */
    const applyLanguage = (lang: string) => {
        if (lang === currentLanguage) {
            closeMenu();
            return;
        }

        i18n.changeLanguage(lang.toLowerCase())
            .catch((error) => {
                console.error(error);
            })
            .then(() => {
                setCurrentLanguage(lang);
            })
            .finally(() => {
                closeMenu();
            });
    };

    /**
     * Open the language menu
     */
    const openMenu = () => {
        setIsMenuOpen(true);
    };

    /**
     * Close the language menu
     */
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <div className="d-flex align-items-center ms-auto position-relative" style={{}} onMouseLeave={closeMenu}>
            <button
                className="navbar-toggler"
                type="button"
                onClick={openMenu}
                aria-expanded={isMenuOpen}
                aria-label="Toggle language menu"
            >
                <span>{currentLanguage}</span>
            </button>
            <div className={`${style.languageMenu} ${isMenuOpen ? "show" : ""}`}
                 style={{display: isMenuOpen ? 'block' : 'none',}}
            >
                <ul className="list-unstyled m-0 p-2">
                    <li className="nav-item" onClick={() => applyLanguage('EN')}>EN</li>
                    <li className="nav-item" onClick={() => applyLanguage('DE')}>DE</li>
                    <li className="nav-item" onClick={() => applyLanguage('RU')}>RU</li>
                </ul>
            </div>
        </div>
    );
};

export default LanguageSwitcher;
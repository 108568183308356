const accountAddOneTimeIncome = {
    en: {
        translation: {
            "account.add.one-time-income.headline": "Add Income",
            "account.add.one-time-income.text.success": "{{amount}} successfully added!",
            "account.add.one-time-income.text.label.account": "Account",
            "account.add.one-time-income.text.label.payment_purpose": "Payment Purpose",
            "account.add.one-time-income.text.select.choose": "Choose...",
            "account.add.one-time-income.text.label.amount": "Amount in {{currency}}-Cents",
            "account.add.one-time-income.text.placeholder.enter_amount": "Enter amount as cents!",
            "account.add.one-time-income.btn.add.text": "Add Income",
            "account.add.one-time-income.btn.cancel.text": "Cancel",
        }
    },
    de: {
        translation: {
            "account.add.one-time-income.headline": "Einnahme hinzufügen",
            "account.add.one-time-income.text.success": "{{amount}} erfolgreich hinzugefügt!",
            "account.add.one-time-income.text.label.account": "Konto",
            "account.add.one-time-income.text.label.payment_purpose": "Zahlungszweck",
            "account.add.one-time-income.text.select.choose": "Auswählen...",
            "account.add.one-time-income.text.label.amount": "Betrag in {{currency}}-Cents",
            "account.add.one-time-income.text.placeholder.enter_amount": "Betrag in Cent eingeben!",
            "account.add.one-time-income.btn.add.text": "Einnahme hinzufügen",
            "account.add.one-time-income.btn.cancel.text": "Abbrechen",
        }
    },
    ru: {
        translation: {
            "account.add.one-time-income.headline": "Добавить доход",
            "account.add.one-time-income.text.success": "{{amount}} успешно добавлено!",
            "account.add.one-time-income.text.label.account": "Счет",
            "account.add.one-time-income.text.label.payment_purpose": "Цель платежа",
            "account.add.one-time-income.text.select.choose": "Выбрать...",
            "account.add.one-time-income.text.label.amount": "Сумма в {{currency}}-центах",
            "account.add.one-time-income.text.placeholder.enter_amount": "Введите сумму в центах!",
            "account.add.one-time-income.btn.add.text": "Добавить доход",
            "account.add.one-time-income.btn.cancel.text": "Отмена",
        }
    }
};

export { accountAddOneTimeIncome };
import {FunctionalComponent, h} from "preact";
import LanguageSwitcher from "./LanguageSwitcher";

const PublicNavigation: FunctionalComponent = () => {
    return (
        <nav className="navbar navbar-dark bg-blue p-2">
            <a className="navbar-brand" href="/"><img src={"../../assets/babylon-logo.svg"} alt="Babylon Logo"
                                                      height={30} width={30}/> Babylon</a>
            <LanguageSwitcher/>
        </nav>
    )
}

export default PublicNavigation;
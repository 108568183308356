const home = {
    en: {
        translation: {
            "home.text.slogan": "Control YOUR MONEY — effortlessly!",
            "home.text.btn.start": "Start now!",
        }
    },
    de: {
        translation: {
            "home.text.slogan": "Kontrolliere DEIN GELD — mühelos!",
            "home.text.btn.start": "Jetzt loslegen!",
        }
    },
    ru: {
        translation: {
            "home.text.slogan": "Контролируйте СВОИ ДЕНЬГИ — без усилий!",
            "home.text.btn.start": "Начать сейчас!",
        }
    }
};

export { home };
const notFound = {
    en: {
        translation: {
            "not_found.headline": "Content not found",
            "not_found.text.not_exist": "The requested content does not exist.",
            "not_found.text.return_link": "Go back",
            "not_found.text.return": "You can go back here:",
        }
    },
    de: {
        translation: {
            "not_found.headline": "Inhalt nicht gefunden",
            "not_found.text.not_exist": "Der angeforderte Inhalt existiert nicht.",
            "not_found.text.return_link": "Zurück",
            "not_found.text.return": "Du kannst hier zurückkehren:",
        }
    },
    ru: {
        translation: {
            "not_found.headline": "Контент не найден",
            "not_found.text.not_exist": "Запрашиваемый контент не существует.",
            "not_found.text.return_link": "Вернуться",
            "not_found.text.return": "Вы можете вернуться сюда:",
        }
    }
};

export { notFound };
import {FunctionalComponent, h, JSX} from "preact";
import {TransactionServiceClient} from "../../../generated/service/TransactionServiceClientPb";
import {useState} from "preact/hooks";
import {CreateTransactionPurposeCommandRequest} from "../../../generated/command/transaction_pb";
import {useTranslation} from "react-i18next";

/**
 * Create purpose form properties.
 */
interface CreatePurposeFormProps {
    txServiceClient: TransactionServiceClient;
    successCallback: () => void;
}

/**
 * Create purpose form component.
 */
const CreatePurposeForm: FunctionalComponent<CreatePurposeFormProps> = ({txServiceClient, successCallback}) => {
    const {t} = useTranslation();
    const minPurposeTitleLength = 3;
    const [newPurposeTitle, setNewPurposeTitle] = useState<string>('');
    const [createPurposeError, setCreatePurposeError] = useState<string>('');

    /**
     * Creates a new payment purpose.
     */
    const createNewPurpose = async (title: string) => {
        const cmd = new CreateTransactionPurposeCommandRequest();
        cmd.setTitle(title);

        await txServiceClient.createTransactionPurpose(cmd);
    }

    /**
     * Handles the form submission to create a new payment purpose.
     * @param e
     */
    const handleCreateNewPurpose = (e: JSX.TargetedEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPurposeTitle.length < minPurposeTitleLength) {
            setCreatePurposeError(t("payment-purposes.create-purpose-form.title_err_len", {minLength: minPurposeTitleLength}));
            return;
        }

        createNewPurpose(newPurposeTitle).then(() => {
            setCreatePurposeError('');
        }).catch((err) => {
            setCreatePurposeError(t("payment-purposes.create-purpose-form.error_message", {errorMessage: err.message}));

            throw err;
        }).finally(() => {
            setNewPurposeTitle('');
            successCallback();
        });
    }

    /**
     * Renders the create payment purpose form.
     */
    return (
        <form onSubmit={handleCreateNewPurpose}>
            <div class={"row mb-5"}>
                <input type="text"
                       placeholder={t("payment-purposes.create-purpose-form.title_placeholder", {minLength: minPurposeTitleLength})}
                       class={"form-control mb-2"}
                       value={newPurposeTitle}
                       onInput={(e) => {
                           setCreatePurposeError('');
                           setNewPurposeTitle((e.target as HTMLInputElement).value);
                       }}
                       required/>
                {createPurposeError && (
                    <div class="alert alert-danger" role="alert">
                        {createPurposeError}
                    </div>
                )}
                <button
                    type={"submit"}
                    class={"btn btn-primary"}
                    disabled={!newPurposeTitle}
                >
                    {t("payment-purposes.create-purpose-form.create_btn_text")}
                </button>
            </div>
        </form>
    );
}

export default CreatePurposeForm;
/**
 * @fileoverview gRPC-Web generated client stub for service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.21.12
// source: service/account.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as command_account_pb from '../command/account_pb'; // proto import: "command/account.proto"
import * as google_protobuf_empty_pb from 'google-protobuf/google/protobuf/empty_pb'; // proto import: "google/protobuf/empty.proto"
import * as query_account_pb from '../query/account_pb'; // proto import: "query/account.proto"


export class AccountServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreateAccount = new grpcWeb.MethodDescriptor(
    '/service.AccountService/CreateAccount',
    grpcWeb.MethodType.UNARY,
    command_account_pb.CreateAccountCommandRequest,
    command_account_pb.CreateAccountResponse,
    (request: command_account_pb.CreateAccountCommandRequest) => {
      return request.serializeBinary();
    },
    command_account_pb.CreateAccountResponse.deserializeBinary
  );

  createAccount(
    request: command_account_pb.CreateAccountCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<command_account_pb.CreateAccountResponse>;

  createAccount(
    request: command_account_pb.CreateAccountCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: command_account_pb.CreateAccountResponse) => void): grpcWeb.ClientReadableStream<command_account_pb.CreateAccountResponse>;

  createAccount(
    request: command_account_pb.CreateAccountCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: command_account_pb.CreateAccountResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/CreateAccount',
        request,
        metadata || {},
        this.methodDescriptorCreateAccount,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/CreateAccount',
    request,
    metadata || {},
    this.methodDescriptorCreateAccount);
  }

  methodDescriptorAddOneTimeExpense = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddOneTimeExpense',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddOneTimeExpenseCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddOneTimeExpenseCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addOneTimeExpense(
    request: command_account_pb.AddOneTimeExpenseCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addOneTimeExpense(
    request: command_account_pb.AddOneTimeExpenseCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addOneTimeExpense(
    request: command_account_pb.AddOneTimeExpenseCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddOneTimeExpense',
        request,
        metadata || {},
        this.methodDescriptorAddOneTimeExpense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddOneTimeExpense',
    request,
    metadata || {},
    this.methodDescriptorAddOneTimeExpense);
  }

  methodDescriptorAddRecurringExpense = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddRecurringExpense',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddRecurringExpenseCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddRecurringExpenseCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addRecurringExpense(
    request: command_account_pb.AddRecurringExpenseCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addRecurringExpense(
    request: command_account_pb.AddRecurringExpenseCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addRecurringExpense(
    request: command_account_pb.AddRecurringExpenseCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddRecurringExpense',
        request,
        metadata || {},
        this.methodDescriptorAddRecurringExpense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddRecurringExpense',
    request,
    metadata || {},
    this.methodDescriptorAddRecurringExpense);
  }

  methodDescriptorAddOneTimeIncome = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddOneTimeIncome',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddOneTimeIncomeCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddOneTimeIncomeCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addOneTimeIncome(
    request: command_account_pb.AddOneTimeIncomeCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addOneTimeIncome(
    request: command_account_pb.AddOneTimeIncomeCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addOneTimeIncome(
    request: command_account_pb.AddOneTimeIncomeCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddOneTimeIncome',
        request,
        metadata || {},
        this.methodDescriptorAddOneTimeIncome,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddOneTimeIncome',
    request,
    metadata || {},
    this.methodDescriptorAddOneTimeIncome);
  }

  methodDescriptorAddRecurringIncome = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddRecurringIncome',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddRecurringIncomeCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddRecurringIncomeCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addRecurringIncome(
    request: command_account_pb.AddRecurringIncomeCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addRecurringIncome(
    request: command_account_pb.AddRecurringIncomeCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addRecurringIncome(
    request: command_account_pb.AddRecurringIncomeCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddRecurringIncome',
        request,
        metadata || {},
        this.methodDescriptorAddRecurringIncome,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddRecurringIncome',
    request,
    metadata || {},
    this.methodDescriptorAddRecurringIncome);
  }

  methodDescriptorAddOneTimeTransfer = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddOneTimeTransfer',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddOneTimeTransferCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddOneTimeTransferCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addOneTimeTransfer(
    request: command_account_pb.AddOneTimeTransferCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addOneTimeTransfer(
    request: command_account_pb.AddOneTimeTransferCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addOneTimeTransfer(
    request: command_account_pb.AddOneTimeTransferCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddOneTimeTransfer',
        request,
        metadata || {},
        this.methodDescriptorAddOneTimeTransfer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddOneTimeTransfer',
    request,
    metadata || {},
    this.methodDescriptorAddOneTimeTransfer);
  }

  methodDescriptorAddRecurringTransfer = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddRecurringTransfer',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddRecurringTransferCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddRecurringTransferCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addRecurringTransfer(
    request: command_account_pb.AddRecurringTransferCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addRecurringTransfer(
    request: command_account_pb.AddRecurringTransferCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addRecurringTransfer(
    request: command_account_pb.AddRecurringTransferCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddRecurringTransfer',
        request,
        metadata || {},
        this.methodDescriptorAddRecurringTransfer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddRecurringTransfer',
    request,
    metadata || {},
    this.methodDescriptorAddRecurringTransfer);
  }

  methodDescriptorAddFutureExpense = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddFutureExpense',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddFutureExpenseCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddFutureExpenseCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addFutureExpense(
    request: command_account_pb.AddFutureExpenseCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addFutureExpense(
    request: command_account_pb.AddFutureExpenseCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addFutureExpense(
    request: command_account_pb.AddFutureExpenseCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddFutureExpense',
        request,
        metadata || {},
        this.methodDescriptorAddFutureExpense,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddFutureExpense',
    request,
    metadata || {},
    this.methodDescriptorAddFutureExpense);
  }

  methodDescriptorAddFutureIncome = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddFutureIncome',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddFutureIncomeCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddFutureIncomeCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addFutureIncome(
    request: command_account_pb.AddFutureIncomeCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addFutureIncome(
    request: command_account_pb.AddFutureIncomeCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addFutureIncome(
    request: command_account_pb.AddFutureIncomeCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddFutureIncome',
        request,
        metadata || {},
        this.methodDescriptorAddFutureIncome,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddFutureIncome',
    request,
    metadata || {},
    this.methodDescriptorAddFutureIncome);
  }

  methodDescriptorAddFutureTransfer = new grpcWeb.MethodDescriptor(
    '/service.AccountService/AddFutureTransfer',
    grpcWeb.MethodType.UNARY,
    command_account_pb.AddFutureTransferCommandRequest,
    google_protobuf_empty_pb.Empty,
    (request: command_account_pb.AddFutureTransferCommandRequest) => {
      return request.serializeBinary();
    },
    google_protobuf_empty_pb.Empty.deserializeBinary
  );

  addFutureTransfer(
    request: command_account_pb.AddFutureTransferCommandRequest,
    metadata?: grpcWeb.Metadata | null): Promise<google_protobuf_empty_pb.Empty>;

  addFutureTransfer(
    request: command_account_pb.AddFutureTransferCommandRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void): grpcWeb.ClientReadableStream<google_protobuf_empty_pb.Empty>;

  addFutureTransfer(
    request: command_account_pb.AddFutureTransferCommandRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: google_protobuf_empty_pb.Empty) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/AddFutureTransfer',
        request,
        metadata || {},
        this.methodDescriptorAddFutureTransfer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/AddFutureTransfer',
    request,
    metadata || {},
    this.methodDescriptorAddFutureTransfer);
  }

  methodDescriptorFetchAccountsOverview = new grpcWeb.MethodDescriptor(
    '/service.AccountService/FetchAccountsOverview',
    grpcWeb.MethodType.UNARY,
    query_account_pb.FetchAccountsOverviewQueryRequest,
    query_account_pb.FetchAccountsOverviewQueryResponse,
    (request: query_account_pb.FetchAccountsOverviewQueryRequest) => {
      return request.serializeBinary();
    },
    query_account_pb.FetchAccountsOverviewQueryResponse.deserializeBinary
  );

  fetchAccountsOverview(
    request: query_account_pb.FetchAccountsOverviewQueryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<query_account_pb.FetchAccountsOverviewQueryResponse>;

  fetchAccountsOverview(
    request: query_account_pb.FetchAccountsOverviewQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: query_account_pb.FetchAccountsOverviewQueryResponse) => void): grpcWeb.ClientReadableStream<query_account_pb.FetchAccountsOverviewQueryResponse>;

  fetchAccountsOverview(
    request: query_account_pb.FetchAccountsOverviewQueryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: query_account_pb.FetchAccountsOverviewQueryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/FetchAccountsOverview',
        request,
        metadata || {},
        this.methodDescriptorFetchAccountsOverview,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/FetchAccountsOverview',
    request,
    metadata || {},
    this.methodDescriptorFetchAccountsOverview);
  }

  methodDescriptorFetchAccountDetails = new grpcWeb.MethodDescriptor(
    '/service.AccountService/FetchAccountDetails',
    grpcWeb.MethodType.UNARY,
    query_account_pb.FetchAccountDetailsQueryRequest,
    query_account_pb.FetchAccountDetailsQueryResponse,
    (request: query_account_pb.FetchAccountDetailsQueryRequest) => {
      return request.serializeBinary();
    },
    query_account_pb.FetchAccountDetailsQueryResponse.deserializeBinary
  );

  fetchAccountDetails(
    request: query_account_pb.FetchAccountDetailsQueryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<query_account_pb.FetchAccountDetailsQueryResponse>;

  fetchAccountDetails(
    request: query_account_pb.FetchAccountDetailsQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: query_account_pb.FetchAccountDetailsQueryResponse) => void): grpcWeb.ClientReadableStream<query_account_pb.FetchAccountDetailsQueryResponse>;

  fetchAccountDetails(
    request: query_account_pb.FetchAccountDetailsQueryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: query_account_pb.FetchAccountDetailsQueryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/FetchAccountDetails',
        request,
        metadata || {},
        this.methodDescriptorFetchAccountDetails,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/FetchAccountDetails',
    request,
    metadata || {},
    this.methodDescriptorFetchAccountDetails);
  }

  methodDescriptorFetchAccountList = new grpcWeb.MethodDescriptor(
    '/service.AccountService/FetchAccountList',
    grpcWeb.MethodType.UNARY,
    query_account_pb.FetchAccountListQueryRequest,
    query_account_pb.FetchAccountListQueryResponse,
    (request: query_account_pb.FetchAccountListQueryRequest) => {
      return request.serializeBinary();
    },
    query_account_pb.FetchAccountListQueryResponse.deserializeBinary
  );

  fetchAccountList(
    request: query_account_pb.FetchAccountListQueryRequest,
    metadata?: grpcWeb.Metadata | null): Promise<query_account_pb.FetchAccountListQueryResponse>;

  fetchAccountList(
    request: query_account_pb.FetchAccountListQueryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: query_account_pb.FetchAccountListQueryResponse) => void): grpcWeb.ClientReadableStream<query_account_pb.FetchAccountListQueryResponse>;

  fetchAccountList(
    request: query_account_pb.FetchAccountListQueryRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: query_account_pb.FetchAccountListQueryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/service.AccountService/FetchAccountList',
        request,
        metadata || {},
        this.methodDescriptorFetchAccountList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/service.AccountService/FetchAccountList',
    request,
    metadata || {},
    this.methodDescriptorFetchAccountList);
  }

}


import MainNavigation from "./navigation";
import {FunctionalComponent, h} from "preact";

const Header: FunctionalComponent = () => {
    return (
        <header>
            <MainNavigation/>
        </header>
    );
};

export default Header;

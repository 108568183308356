import {FunctionalComponent, h} from "preact";
import AuthClientService from "../../../auth/AuthClientService";
import {useUser} from "../../../auth/UserProvider";
import {useState} from "preact/hooks";
import {useEffect} from "preact/compat";
import {useTranslation} from "react-i18next";

export interface SignOutProps {
    readonly authClient: AuthClientService;
}

const SignOut: FunctionalComponent<SignOutProps> = ({authClient}) => {
    const {setUser} = useUser();
    const {t} = useTranslation();
    const [isSignedOut, setIsSignedOut] = useState(false);

    useEffect(() => {
        const signOut = async () => {
            try {
                await authClient.signOut();
                setUser(null);
            } catch (e) {
                console.error(e);
            } finally {
                setIsSignedOut(true);
            }
        };

        signOut().finally();
    }, []);

    if (!isSignedOut) {
        return <div>{t("logout.signing_out")}</div>;
    }

    return (
        <div class="container">
            {t("logout.signed_out")}
        </div>
    );
};

export default SignOut;
const accountAddOneTimeTransfer = {
    en: {
        translation: {
            "account.add.one-time-transfer.headline": "Transfer",
            "account.add.one-time-transfer.text.success": "Successfully transferred {{amount}}.",
            "account.add.one-time-transfer.text.label.src_account": "Source Account",
            "account.add.one-time-transfer.text.label.dst_account": "Destination Account",
            "account.add.one-time-transfer.text.label.payment_purpose": "Purpose",
            "account.add.one-time-transfer.text.label.amount": "Amount ({{currency}})",
            "account.add.one-time-transfer.text.select.choose_purpose": "Choose a purpose...",
            "account.add.one-time-transfer.text.select.choose_dst_account": "Choose a destination account...",
            "account.add.one-time-transfer.text.placeholder.enter_amount": "Enter amount",
            "account.add.one-time-transfer.btn.add.text": "Transfer",
            "account.add.one-time-transfer.btn.cancel.text": "Cancel"
        }
    },
    de: {
        translation: {
            "account.add.one-time-transfer.headline": "Übertragung",
            "account.add.one-time-transfer.text.success": "Erfolgreich {{amount}} übertragen.",
            "account.add.one-time-transfer.text.label.src_account": "Quellkonto",
            "account.add.one-time-transfer.text.label.dst_account": "Zielkonto",
            "account.add.one-time-transfer.text.label.payment_purpose": "Verwendungszweck",
            "account.add.one-time-transfer.text.label.amount": "Betrag ({{currency}})",
            "account.add.one-time-transfer.text.select.choose_purpose": "Verwendungszweck auswählen...",
            "account.add.one-time-transfer.text.select.choose_dst_account": "Zielkonto auswählen...",
            "account.add.one-time-transfer.text.placeholder.enter_amount": "Betrag eingeben",
            "account.add.one-time-transfer.btn.add.text": "Übertragen",
            "account.add.one-time-transfer.btn.cancel.text": "Abbrechen"
        }
    },
    ru: {
        translation: {
            "account.add.one-time-transfer.headline": "Передача",
            "account.add.one-time-transfer.text.success": "Успешно передано {{amount}}.",
            "account.add.one-time-transfer.text.label.src_account": "Счет отправителя",
            "account.add.one-time-transfer.text.label.dst_account": "Счет получателя",
            "account.add.one-time-transfer.text.label.payment_purpose": "Назначение",
            "account.add.one-time-transfer.text.label.amount": "Сумма ({{currency}})",
            "account.add.one-time-transfer.text.select.choose_purpose": "Выберите назначение...",
            "account.add.one-time-transfer.text.select.choose_dst_account": "Выберите счет получателя...",
            "account.add.one-time-transfer.text.placeholder.enter_amount": "Введите сумму",
            "account.add.one-time-transfer.btn.add.text": "Передать",
            "account.add.one-time-transfer.btn.cancel.text": "Отмена"
        }
    }
};

export { accountAddOneTimeTransfer };